<script lang="ts" setup>
const { open } = useFunrizeModals();
const { currentTask, questData } = useGameQuest();

const handleClick = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "left_menu",
		button_name: "advent_calendar"
	});

	open("LazyOModalAdventCalendar");
};
</script>

<template>
	<div class="wheel-banner" @click="handleClick">
		<div class="wrapper">
			<div class="info">
				<AText :modifiers="['bold']" class="text-coimbatore">{{ questData?.questInfo?.title }}</AText>
			</div>
		</div>
		<ABadge v-if="currentTask">
			<AText variant="texas">1</AText>
		</ABadge>
	</div>
</template>

<style scoped lang="scss">
.wheel-banner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-shrink: 0;
	height: 56px;
	background: var(--goma);
	border-radius: 8px;
	position: relative;
	cursor: pointer;

	.wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;

		width: 100%;
		height: 100%;
		overflow: hidden;
		padding: 8px;
		background: url("/nuxt-img/advent/sidebar.png") no-repeat center center / cover;
	}

	.wheel-img {
		transform: translateX(23px) rotate(-22.5deg);
	}
}

.info {
	display: inline-flex;
	flex-direction: column;
	gap: 2px;
	width: 120px;
}

.badge {
	position: absolute;
	top: -6px;
	right: -6px;
	z-index: 1;
}
</style>
